import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
	InMemoryScrollingOptions,
	provideRouter,
	withComponentInputBinding,
	withInMemoryScrolling,
	withViewTransitions,
} from '@angular/router';
import { routes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { ToasterComponent } from '@shared/components/toaster';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HeadersInterceptor } from '@core/interceptor/headers-interceptor';

const scrollConfig: InMemoryScrollingOptions = {
	scrollPositionRestoration: 'enabled',
	anchorScrolling: 'disabled',
};

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(
			routes,
			withComponentInputBinding(),
			withViewTransitions(),
			withInMemoryScrolling(scrollConfig),
		),
		provideHttpClient(withInterceptors([HeadersInterceptor])),
		provideAnimations(),
		importProvidersFrom(
			TranslateModule.forRoot({
				defaultLanguage: 'en',
				loader: {
					provide: TranslateLoader,
					useFactory: (httpHandler: HttpBackend) => {
						return new TranslateHttpLoader(
							new HttpClient(httpHandler),
							'./assets/i18n/',
						);
					},
					deps: [HttpBackend],
				},
			}),
		),
		importProvidersFrom(
			ToastrModule.forRoot({
				toastComponent: ToasterComponent,
			}),
		),
	],
};
