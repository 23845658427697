import {
	booleanAttribute,
	Component,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	numberAttribute,
	Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { TColor } from '@shared/interfaces';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SecondaryLayerColorDirective } from '@shared/directives';
import { CustomButtonComponent } from '@shared/components/custom-button';
import { AppStyles } from '@shared/services/app-styles.service';

@Component({
	standalone: true,
	selector: 'nothing-found',
	template: `
		<div class="nothing-found flex-column align-center-center height-100">
			<icon
				[name]="icon"
				[color]="iconColor"
				[customColor]="iconCustomColor"
				[size]="iconSize" />

			@if (message) {
				<div class="message" [class.item-margin-bottom]="!!messageHint">
					{{ message | translate }}
				</div>
			}

			@if (messageHint) {
				<div class="text-uppercase message-hint text-center font-size-sm">
					{{ messageHint | translate }}
				</div>
			}
		</div>

		@if (showTryAgain) {
			<div class="align-center-center">
				<custom-button
					class="item-margin-top"
					type="stroked"
					label="global.try_again"
					icon="autorenew"
					color="primary"
					(click)="tryAgain.emit()" />
			</div>
		}
	`,
	styles: `
		:host {
			border-radius: var(--border-radius);
			display: block;
		}

		.nothing-found .message {
			font-weight: var(--font-weight-semi-bold);
			color: var(--text-color);
			text-wrap: balance;
			text-align: center;
		}

		.nothing-found .message-hint {
			white-space: pre-line;
		}
	`,
	imports: [
		TranslateModule,
		IconComponent,
		MatProgressSpinnerModule,
		SecondaryLayerColorDirective,
		CustomButtonComponent,
	],
})
export class NothingFoundComponent {
	private appStyles: AppStyles = inject(AppStyles);

	@Input({ required: true }) icon: TIcon;
	@Input({ required: true }) message: string | null;
	@Input() messageHint?: string;
	@Input({ transform: numberAttribute }) iconSize: number = 50;
	@Input() iconColor: TColor = 'primary';
	@Input() iconCustomColor: string;
	@Input({ transform: booleanAttribute }) showTryAgain: boolean;
	@Input({ transform: booleanAttribute }) removePadding: boolean;

	@Output() tryAgain: EventEmitter<void> = new EventEmitter<void>();

	@HostBinding('style')
	get style() {
		return {
			padding: this.removePadding ? '' : this.appStyles.styles().containerPadding,
			backgroundColor: this.appStyles.styles().backgroundBlueLight,
		};
	}
}
