import {
	afterNextRender,
	booleanAttribute,
	Component,
	effect,
	ElementRef,
	HostBinding,
	inject,
	input,
	Input,
	InputSignalWithTransform,
	numberAttribute,
	Signal,
	ViewChild,
} from '@angular/core';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { MatRippleModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IRootVariable, TColor } from '@shared/interfaces';
import { AppStyles } from '@shared/services/app-styles.service';
import { TButtonType } from '@shared/components/custom-button/custom-button-type.interface';

@Component({
	standalone: true,
	selector: 'custom-button',
	templateUrl: './custom-button.component.html',
	styleUrl: './custom-button.component.scss',
	imports: [IconComponent, MatRippleModule, TranslateModule, MatProgressSpinnerModule],
})
export class CustomButtonComponent {
	private appStyles: AppStyles = inject(AppStyles);

	styles: Signal<IRootVariable> = this.appStyles.styles;

	title: string = ''; // used to set attribute title in button tag

	// states
	loading: InputSignalWithTransform<boolean, unknown> = input(false, {
		transform: booleanAttribute,
	});
	// keeps button 'alive' with spinner inside
	loadingInside: InputSignalWithTransform<boolean, unknown> = input(false, {
		transform: booleanAttribute,
	});
	// keeps button 'alive' with spinner aside label
	loadingWithLabel: InputSignalWithTransform<boolean, unknown> = input(false, {
		transform: booleanAttribute,
	});
	disabled: InputSignalWithTransform<boolean, unknown> = input(false, {
		transform: booleanAttribute,
	});
	active: InputSignalWithTransform<boolean, unknown> = input(false, {
		transform: booleanAttribute,
	});

	// button styles
	@Input() type?: TButtonType;
	@Input() color: TColor | undefined;
	@Input() loadingClass: string;
	@Input() bgCustomColor: string;
	@Input({ transform: numberAttribute }) customPadding: number;
	@Input({ transform: booleanAttribute }) fill: boolean; // fill space from left to right
	@Input({ transform: booleanAttribute }) fillStart: boolean; // fill space from left to right
	@Input({ transform: booleanAttribute }) small: boolean;
	@Input({ transform: booleanAttribute }) removeHover: boolean;
	@Input({ transform: booleanAttribute }) textUppercase: boolean;
	@Input({ transform: booleanAttribute }) fontSizeSm: boolean;
	@Input({ transform: booleanAttribute }) fontSizeMd: boolean;
	@Input({ transform: booleanAttribute }) fontSizeLg: boolean;
	@Input({ transform: numberAttribute }) minWidth: number;
	@Input({ transform: booleanAttribute }) removeBold: boolean;
	@Input({ transform: booleanAttribute }) border: boolean;
	@Input({ transform: booleanAttribute }) oneLine: boolean = true;
	@Input({ transform: booleanAttribute }) borderRadiusMd: boolean;

	// label
	@Input() label: string;

	// icon
	@Input() icon: TIcon;
	@Input({ transform: numberAttribute }) iconSize: number =
		this.appStyles.getNumberFromPixels('iconSize');
	@Input({ transform: booleanAttribute }) iconFilled: boolean;
	@Input({ transform: booleanAttribute }) iconOnly: boolean;
	@Input({ transform: booleanAttribute }) iconOnlyNoPadding: boolean;
	@Input() iconCustomColor: string;

	@HostBinding('class.custom-button') customButtonClass = true;

	@HostBinding('class.pointer-events-none')
	get pointerEventNoneClass() {
		return this.loading() || this.disabled() || this.loadingInside();
	}

	@HostBinding('style')
	get style() {
		return {
			'--custom-button-text-color': this.getTextColor(),
			'--custom-button-background-color': this.getBackgroundColor(),
			'--custom-button-hover-color': this.appStyles.toOpacity(
				undefined,
				this.getTextColor(),
				0.08,
			),
			'--custom-button-selected-color': this.appStyles.toOpacity(
				undefined,
				this.getTextColor(),
				0.18,
			),
			'--custom-button-padding': this.customPadding + 'px',
			'--custom-button-min-width': this.minWidth + 'px',
		};
	}

	@ViewChild('button', { read: ElementRef }) button: ElementRef;

	buttonWidth: number = 0;

	constructor() {
		effect(() => {
			if (!this.loading()) {
				this.setText();
				setTimeout(() => {
					this.buttonWidth = this.button.nativeElement.offsetWidth;
				}, 100);
			}
		});

		afterNextRender(() => {
			this.setText();
		});
	}

	getTextColor(): string {
		if (this.disabled()) return this.styles().textColorDisabled;

		if (this.iconCustomColor) return this.iconCustomColor;

		const color: string = this.color
			? this.appStyles.getColor(this.color)
			: this.styles().textColor;

		switch (this.type) {
			case 'flat':
				if (this.color == 'blackWhite') return this.styles().textColorInverse;
				return '#FFFFFF';
			case 'flat-inverse':
				return color;
			case 'flat-inverse-color':
			case 'stroked':
				return color;
		}

		return color;
	}

	getBackgroundColor(): string {
		if (this.disabled() && this.type) return this.styles().disabledColor;

		if (this.bgCustomColor) return this.bgCustomColor;

		const color: string = this.color ? this.appStyles.getColor(this.color) : '';

		switch (this.type) {
			case undefined:
				return 'transparent';
			case 'flat-inverse':
				return this.styles().hoverColor;
			case 'flat-inverse-color':
				return this.appStyles.toOpacity(undefined, color, 0.08);
		}

		return color;
	}

	setText(): void {
		setTimeout(() => {
			if (this.button)
				this.title = this.button.nativeElement.textContent.replace(this.icon, '').trim();
		}, 10);
	}
}
