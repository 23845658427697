import { effect, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { AppStorageService } from '@shared/services/app-storage.service';
import { environment } from '@env/environment';

const STORAGE_NAME_UUID = environment.localStorageName + '_uuid';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	private appStorage: AppStorageService = inject(AppStorageService);

	private _loading: WritableSignal<boolean> = signal(true);
	private _error: WritableSignal<boolean> = signal(false);
	private _svgIconsLoaded: WritableSignal<string[]> = signal([]);
	private _menuState: WritableSignal<boolean> = signal(true);
	private _uuid: WritableSignal<string> = signal('');
	private _isMobile: WritableSignal<boolean> = signal(
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
	);

	loading: Signal<boolean> = this._loading.asReadonly();
	error: Signal<boolean> = this._error.asReadonly();
	menuState: Signal<boolean> = this._menuState.asReadonly();
	svgIconsLoaded: Signal<string[]> = this._svgIconsLoaded.asReadonly();
	uuid: Signal<string> = this._uuid.asReadonly();
	isMobile: Signal<boolean> = this._isMobile.asReadonly();

	constructor() {
		effect(() => {
			this.appStorage.setItem(STORAGE_NAME_UUID, this.uuid());
		});
	}

	fnInit() {
		let uuid = this.appStorage.getItem(STORAGE_NAME_UUID);
		if (!uuid) uuid = this.generateUUID();
		this._uuid.set(uuid);
	}

	addSvgIconsLoaded(value: string[]): void {
		const icons: Set<string> = new Set([...this.svgIconsLoaded(), ...value]);
		this._svgIconsLoaded.set([...icons]);
	}

	startLoading(): void {
		this._loading.set(true);
	}

	stopLoading(): void {
		this._loading.set(false);
	}

	hasError(): void {
		this._error.set(true);
	}

	clearError(): void {
		this._error.set(false);
	}

	hideMenu(): void {
		this._menuState.set(false);
	}

	showMenu(): void {
		this._menuState.set(true);
	}

	private generateUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0;
			const v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	}
}
