import { Component, HostBinding, inject, OnInit, Signal } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { CustomCardComponent } from '@shared/components/custom-card';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent, TIcon } from '@shared/components/icon/icon.component';
import { IRootVariable } from '@shared/interfaces';
import { AppStyles } from '@shared/services/app-styles.service';
import { AppBackgroundDirective } from '@shared/directives';
import { ThemeService, TTheme } from '@shared/services';
import { CustomButtonComponent } from '@shared/components/custom-button';

// https://github.com/scttcper/ngx-toastr/blob/master/src/app/pink.toast.ts

@Component({
	standalone: true,
	selector: 'app-toaster',
	template: `
		<custom-card
			class="flex-row align-start-center"
			[style.background-color]="color"
			paddingSmall>
			@if (icon) {
				<icon customColor="white" [name]="icon" size="30" />
			}
			<div class="flex-column align-center-start item-margin-left">
				<div class="font-size-md">{{ title || '' | translate }}</div>
				<div>{{ message || '' | translate }}</div>
			</div>

			<div class="flex align-end-center">
				<custom-button
					icon="close"
					iconOnly
					iconCustomColor="white"
					iconSize="30"
					(click)="toastrService.clear(toastrService.currentlyActive)" />
			</div>
		</custom-card>
	`,
	styles: `
		.custom-card {
			border-radius: var(--border-radius-small);
			border: none;
			min-width: 250px;
			color: white;
			margin: var(--item-margin);
		}

		&.ngx-toastr {
			--offset: calc(var(--page-margin) * 2);
			width: calc(100% - var(--offset));
			box-shadow: none;
			border-radius: 0;
			background-color: transparent;
			background-image: none;
			padding: 0;
			margin: 0 var(--page-margin);
			position: relative;

			&:hover {
				box-shadow: none;
				cursor: default;
			}

			.icon {
				box-sizing: unset;
			}

			.message {
				margin-left: var(--item-margin);
				font-size: var(--font-size-md);
			}
		}
	`,
	imports: [
		CustomCardComponent,
		TranslateModule,
		IconComponent,
		AppBackgroundDirective,
		CustomButtonComponent,
	],
})
export class ToasterComponent extends Toast implements OnInit {
	private appStyles: AppStyles = inject(AppStyles);

	styles: Signal<IRootVariable> = inject(AppStyles).styles;
	theme: Signal<TTheme> = inject(ThemeService).theme;

	undoString = 'undo';
	color: string;
	icon: TIcon;

	@HostBinding('style.--color')
	get _color() {
		return this.color;
	}

	ngOnInit() {
		const opacity: number = 1;

		switch (this.toastPackage.toastType) {
			case 'toast-success':
				this.color = this.appStyles.toOpacity('greenColor', '', opacity);
				this.icon = 'check-circle';
				break;
			case 'toast-error':
				this.color = this.appStyles.toOpacity('warnColor', '', opacity);
				this.icon = 'error_outline';
				break;
			case 'toast-warning':
				this.color = this.appStyles.toOpacity('orangeColor', '', opacity);
				this.icon = 'alert-triangle-filled';
				break;
			case 'toast-info':
				this.color = this.appStyles.toOpacity('primaryColor', '', opacity);
				this.icon = 'info';
				break;
		}
	}

	action(event: Event) {
		event.stopPropagation();
		this.undoString = 'undid';
		this.toastPackage.triggerAction();
		return false;
	}
}
