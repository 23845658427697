@if (!loading()) {
	<button
		class="custom-button {{ type }} {{ color }}"
		[attr.title]="title || (label | translate)"
		[class.loading-inside]="loadingInside()"
		[class.fill]="fill"
		[class.fill-start]="fillStart"
		[class.selected]="active()"
		[class.small]="small"
		[class.icon-only]="iconOnly"
		[class.icon-only-no-padding]="iconOnlyNoPadding"
		[class.custom-padding]="customPadding"
		[class.remove-hover]="removeHover"
		[class.min-width]="!!minWidth"
		[class.text-uppercase]="textUppercase"
		[class.text-bold]="!removeBold"
		[class.one-line]="oneLine"
		[class.border-hover]="type === 'flat-inverse-color'"
		[class.font-size-normal]="!fontSizeSm && !fontSizeMd && !fontSizeLg"
		[class.font-size-sm]="fontSizeSm"
		[class.font-size-md]="fontSizeMd"
		[class.font-size-lg]="fontSizeLg"
		[class.border-radius-md]="borderRadiusMd"
		[style.min-width]="buttonWidth + 'px'"
		[matRippleDisabled]="removeHover"
		#button
		matRipple>
		@if (loadingInside()) {
			<mat-spinner [diameter]="iconSize" strokeWidth="3" />
		} @else {
			@if (icon) {
				<icon
					[name]="icon"
					[size]="iconSize"
					[filled]="iconFilled"
					[customColor]="getTextColor()" />
			}
			@if (!iconOnly) {
				<div class="flex-row align-start-center">
					@if (loadingWithLabel()) {
						<mat-spinner
							[diameter]="iconSize"
							strokeWidth="3"
							class="icon-btn-margin-right" />
					}
					{{ label | translate }}
					<ng-content></ng-content>
				</div>
			}
		}
	</button>
} @else {
	<div class="loading {{ loadingClass }}">
		<div
			class="item height"
			[class.icon]="iconOnly"
			[class.button]="!iconOnly"
			[class.border-radius-btn]="!iconOnly"
			[class.width-100]="fill"></div>
	</div>
}
