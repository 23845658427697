import {
	booleanAttribute,
	Component,
	effect,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	inject,
	Input,
	numberAttribute,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CustomCardService } from '@shared/components/custom-card/custom-card.service';
import { DestroyerComponent } from '@shared/components/destroyer-component';
import { TColor } from '@shared/interfaces';
import { AppStyles } from '@shared/services/app-styles.service';

@Component({
	standalone: true,
	selector: 'custom-card',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./custom-card.component.scss'],
	providers: [CustomCardService],
	animations: [
		trigger('customCardExpanded', [
			state(
				'collapsed',
				style({
					height: 'calc(var(--header-height) + var(--container-padding-sm) + var(--container-padding-sm))',
				}),
			),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class CustomCardComponent extends DestroyerComponent implements OnChanges {
	private hostElement: ElementRef = inject(ElementRef);
	private customCardService: CustomCardService = inject(CustomCardService);
	private appStyles: AppStyles = inject(AppStyles);

	@Input({ transform: booleanAttribute }) fullHeight: boolean | null;
	@Input({ transform: booleanAttribute }) removePaddingBottom: boolean | null;
	@Input({ transform: booleanAttribute }) removePaddingTop: boolean | null;
	@Input({ transform: booleanAttribute }) removeBgColor: boolean | null;
	@Input({ transform: booleanAttribute }) removePadding: boolean | null;
	@Input({ transform: booleanAttribute }) removePaddingInline: boolean | null;
	@Input({ transform: booleanAttribute }) removeBoxShadow: boolean | null;
	@Input({ transform: booleanAttribute }) removeBorderRadius: boolean | null;
	@Input({ transform: booleanAttribute }) paddingSmall: boolean | null;
	@Input({ transform: booleanAttribute }) paddingSuperSmall: boolean | null;
	@Input({ transform: booleanAttribute }) paddingInlineSmall: boolean | null;
	@Input({ transform: booleanAttribute }) paddingBlockSmall: boolean | null;
	@Input({ transform: booleanAttribute }) paddingBlockSuperSmall: boolean | null;
	@Input({ transform: booleanAttribute }) overflowUnset: boolean | null;
	@Input({ transform: booleanAttribute }) allowBackground: boolean | null;
	@Input({ transform: numberAttribute }) width: number;
	@Input({ transform: booleanAttribute }) fill: boolean | null;
	@Input({ transform: booleanAttribute }) sticky: boolean; // only sticks when window scroll is trigger
	@Input({ transform: booleanAttribute }) fixedSticky: boolean; // always sticky
	@Input({ transform: booleanAttribute }) border: boolean;
	@Input({ transform: booleanAttribute }) borderBottom: boolean;
	@Input({ transform: booleanAttribute }) borderTransparent: boolean;
	@Input({ transform: booleanAttribute }) expanded: boolean; // closed / opened
	@Input({ transform: booleanAttribute }) flex: boolean;
	@Input({ transform: numberAttribute }) headerHeight: number;
	@Input() borderColor: TColor;

	@Output() expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	isSticky: boolean = false;

	constructor() {
		super();

		effect(() => {
			this.expandedChange.emit(this.customCardService.state());
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['expanded']) {
			if (this.expanded) this.customCardService.open();
			else this.customCardService.close();
		}
	}

	@HostBinding('class')
	get class() {
		return {
			'custom-card': true,
			'remove-padding-bottom': this.removePaddingBottom,
			'remove-padding-top': this.removePaddingTop,
			'remove-background-color': this.removeBgColor,
			'remove-padding': this.removePadding,
			'remove-padding-inline': this.removePaddingInline,
			'remove-box-shadow': this.removeBoxShadow,
			'remove-border-radius': this.removeBorderRadius,
			'padding-small': this.paddingSmall,
			'padding-inline-small': this.paddingInlineSmall,
			'padding-block-small': this.paddingBlockSmall,
			'padding-block-super-small': this.paddingBlockSuperSmall,
			'padding-super-small': this.paddingSuperSmall,
			'overflow-unset': this.overflowUnset,
			'hover-background': this.allowBackground,
			'border': this.border,
			'border-bottom': this.borderBottom,
			'border-transparent': this.borderTransparent,
			'full-height': this.fullHeight,
			'width-100': this.fill,
			'sticky': this.isSticky || this.fixedSticky,
		};
	}

	@HostBinding('style')
	get style() {
		const computedStyle = window.getComputedStyle(this.hostElement.nativeElement);
		const displayFlex = computedStyle.getPropertyValue('display') == 'flex';
		return {
			'display': displayFlex || this.flex ? '' : 'block',
			'width': this.width + 'px',
			'border-color': this.appStyles.getColor(this.borderColor),
			'--header-height': this.headerHeight ? this.headerHeight + 'px' : '30px',
		};
	}

	@HostBinding('@customCardExpanded')
	get animationState() {
		return this.customCardService.state() ? 'expanded' : 'collapsed';
	}

	@HostListener('@customCardExpanded.done', ['$event'])
	onAnimationDone(event: AnimationEvent) {
		if (!this.customCardService.state()) this.customCardService.hideContent();
	}

	@HostListener('window:scroll', [])
	onScroll(): void {
		if (!this.sticky) return;

		const rect = this.hostElement.nativeElement.getBoundingClientRect();
		this.isSticky = rect.y <= 0;
	}
}
