import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomSpinnerComponent } from '@shared/components/custom-spinner';
import { translations } from '@shared/utils/translations';
import { HeaderComponent } from '@shared/components/header';
import { LandingComponent } from '@pages/landing/landing.component';
import { AppStarterService } from '@shared/services';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [CustomSpinnerComponent, HeaderComponent, LandingComponent, RouterOutlet],
	template: ` <router-outlet /> `,
	styles: `
		:host {
		}
	`,
})
export class AppComponent {
	protected readonly translations = translations;

	private appStarterService: AppStarterService = inject(AppStarterService);

	constructor() {
		this.appStarterService.initServices();
	}
}
