import { Inject, inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class AppLanguageService {
	private translate: TranslateService = inject(TranslateService);

	constructor(@Inject(LOCALE_ID) public locale: string) {}

	fnInit() {
		this.translate.use(window.navigator.language);
		// this.translate.use('pt');
	}
}
