import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import {
	IRootVariable,
	TBackgroundColor,
	TBorderRadius,
	TColor,
	TFontSize,
	TTextColor,
} from '@shared/interfaces';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class AppStyles {
	// ⚠️ list auto generated via script (css-variables-generator.js)
	private static cssVariables: string[] = [
		'--font-family',
		'--font-family-kone',
		'--font-weight-semi-bold',
		'--font-weight-normal',
		'--font-size-xs',
		'--font-size-sm',
		'--font-size-normal',
		'--font-size-md',
		'--font-size-lg',
		'--font-size-super-lg',
		'--font-size-xl',
		'--font-size-super-xl',
		'--font-size-super-lg-xl',
		'--icon-size',
		'--page-min-width',
		'--menu-width',
		'--notification-width',
		'--equipment-side-width',
		'--button-height',
		'--button-height-small',
		'--item-height',
		'--header-height',
		'--border-radius',
		'--border-radius-md',
		'--border-radius-lg',
		'--border-radius-btn',
		'--border-radius-small',
		'--page-margin',
		'--page-margin-half',
		'--item-margin',
		'--item-margin-half',
		'--icon-btn-margin',
		'--container-padding-md',
		'--container-padding',
		'--container-padding-sm',
		'--container-padding-super-sm',
		'--transition-duration',
		'--transition-duration-half',
		'--primary-color',
		'--warn-color',
		'--orange-color',
		'--green-color',
		'--purple-color',
		'--teal-color',
		'--yellow-color',
		'--magenta-color',
		'--ice-blue-color',
		'--black-white',
		'--app-background',
		'--primary-layer',
		'--secondary-layer',
		'--tertiary-layer',
		'--fourth-layer',
		'--fifth-layer',
		'--background-grey',
		'--background-blue-light',
		'--button-color',
		'--loading-background',
		'--loading-background-strong',
		'--text-color',
		'--text-color-soft',
		'--text-color-disabled',
		'--hover-color',
		'--strong-hover-color',
		'--divider-color',
		'--divider-stronger-color',
		'--disabled-color',
		'--color-inverse',
		'--text-color-inverse',
		'--text-color-inverse-soft',
		'--box-shadow-color',
	];

	private allowLog = environment.system == 'LOCAL_DEV' || environment.system == 'DEV';
	private _styles: WritableSignal<IRootVariable> = signal({} as IRootVariable);

	styles: Signal<IRootVariable> = this._styles.asReadonly();

	constructor() {
		this.set();
	}

	set(): void {
		this._styles.set(
			AppStyles.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
				acc[AppStyles.convertCssVarToCamelCase(rootVar)] = AppStyles.getRootValue(rootVar);
				return acc;
			}, {}) as unknown as IRootVariable,
		);
		AppStyles.initializeStaticStyles();
		// if (this.allowLog) console.log('AppStyles', this.styles(), styles);
	}

	getBorderRadius(borderRadius: TBorderRadius): string {
		if (borderRadius == 'default') return this.styles().borderRadius;
		else if (borderRadius == 'btn') return this.styles().borderRadiusBtn;
		else if (borderRadius == 'small') return this.styles().borderRadiusSmall;
		else if (borderRadius == 'md') return this.styles().borderRadiusMd;
		else if (borderRadius == 'lg') return this.styles().borderRadiusLg;
		else return borderRadius;
	}

	getBackgroundColor(backgroundColor: TBackgroundColor): string {
		if (backgroundColor == 'primaryLayer') return this.styles().primaryLayer;
		else if (backgroundColor == 'secondaryLayer') return this.styles().secondaryLayer;
		else if (backgroundColor == 'tertiaryLayer') return this.styles().tertiaryLayer;
		else if (backgroundColor == 'fourthLayer') return this.styles().fourthLayer;
		else if (backgroundColor == 'fifthLayer') return this.styles().fifthLayer;
		else return backgroundColor;
	}

	getColor(color: TColor): string {
		if (color == 'primary') return this.styles().primaryColor;
		else if (color == 'warn') return this.styles().warnColor;
		else if (color == 'green') return this.styles().greenColor;
		else if (color == 'orange') return this.styles().orangeColor;
		else if (color == 'purple') return this.styles().purpleColor;
		else if (color == 'teal') return this.styles().tealColor;
		else if (color == 'yellow') return this.styles().yellowColor;
		else if (color == 'blackWhite') return this.styles().colorInverse;
		else return color;
	}

	getTextColor(textColor: TTextColor): string {
		if (textColor == 'default') return this.styles().textColor;
		else if (textColor == 'soft') return this.styles().textColorDisabled;
		else if (textColor == 'disabled') return this.styles().textColorDisabled;
		else return textColor;
	}

	getFontSize(fontSize: TFontSize): string {
		if (fontSize == 'normal') return this.styles().fontSizeNormal;
		else if (fontSize == 'xs') return this.styles().fontSizeXs;
		else if (fontSize == 'sm') return this.styles().fontSizeSm;
		else if (fontSize == 'md') return this.styles().fontSizeMd;
		else if (fontSize == 'lg') return this.styles().fontSizeLg;
		else if (fontSize == 'superLg') return this.styles().fontSizeSuperLg;
		else if (fontSize == 'xl') return this.styles().fontSizeXl;
		else if (fontSize == 'superLgXl') return this.styles().fontSizeSuperLgXl;
		else if (fontSize == 'superXl') return this.styles().fontSizeSuperXl;
		else return fontSize;
	}

	getNumberFromPixels(key: keyof IRootVariable): number {
		const pixels = this.styles()[key];
		if (typeof pixels == 'string') return Number(pixels.replace('px', ''));
		return 0;
	}

	getNumberFromTime(key: keyof IRootVariable): number {
		const pixels = this.styles()[key];
		if (typeof pixels == 'string') return Number(pixels.replace('ms', ''));
		return 0;
	}
	toOpacity(key?: keyof IRootVariable, color?: string, alpha?: number): string {
		if (key) color = this.styles()[key] as string;

		if (!color) return '';

		let r, g, b;
		if (color.includes('rgba')) {
			const split = color.split('rgba(')[1];
			const values = split.split(',');
			r = Number(values[0]);
			g = Number(values[1]);
			b = Number(values[2]);
		} else {
			color = color.toUpperCase();
			r = parseInt(color.slice(1, 3), 16);
			g = parseInt(color.slice(3, 5), 16);
			b = parseInt(color.slice(5, 7), 16);
		}

		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	}

	static convertCssVarToCamelCase(cssVar: string): string {
		// Remove the initial two dashes
		const cleanVar = cssVar.substring(2);

		// Split the string into words using hyphen as separator
		const words = cleanVar.split('-');

		// Convert to camelCase: leave the first word as is, capitalize subsequent words
		return words
			.map((word, index) => {
				return index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join('');
	}

	private static getRootValue(rootVar: string): string {
		const rootStyles = getComputedStyle(document.documentElement);
		const value: string = rootStyles.getPropertyValue(rootVar).trim();
		// Convert the string to a number (in seconds), then to milliseconds
		if (value.includes('s') && !value.includes('ms')) {
			const milliseconds = parseFloat(value) * 1000;
			return milliseconds.toString() + 'ms';
		}
		return value;
	}

	public static get staticStyles(): IRootVariable {
		return this.cssVariables.reduce((acc: { [key: string]: string }, rootVar: string) => {
			acc[this.convertCssVarToCamelCase(rootVar)] = this.getRootValue(rootVar);
			return acc;
		}, {}) as unknown as IRootVariable;
	}

	public static initializeStaticStyles(): void {
		styles = AppStyles.staticStyles;
	}
}

export let styles: IRootVariable = AppStyles.staticStyles;
