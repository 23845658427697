import { Injectable } from '@angular/core';
import {
	IRouteAccessConfig,
	TChildrenPageType,
	TPageType,
	TRouteAccess,
} from '@shared/interfaces/route.interface';

@Injectable({
	providedIn: 'root',
})
export class AppRouteService {
	get routeAccess(): TRouteAccess {
		return {
			landing: {
				canActivate: [],
				userTypes: [],
				routeConfig: {
					path: 'landing',
				},
			},
			info: {
				canActivate: [],
				userTypes: [],
				routeConfig: {
					path: 'info',
				},
			},
		};
	}

	get defaultRoute(): TPageType {
		return 'landing';
	}

	getMenuRoutes(): string[] {
		const getChildrenPaths = (page: TPageType): string[] => {
			const children: any = this.routeAccess[page]?.routeConfig.children ?? {};
			return Object.keys(children)
				.filter(child => children[child].mainRoute)
				.map(child => this.getPath(page) + '/' + child);
		};

		const parentRoutes = Object.keys(this.routeAccess)
			.filter((page: string) => this.routeAccess[page as TPageType].visibleOnMenu)
			.map((page: string) => this.getPath(page as TPageType));

		return parentRoutes.concat(
			...Object.keys(this.routeAccess).map(page => getChildrenPaths(page as TPageType)),
		);
	}

	getRouteAvailability<T extends TPageType>(config: IRouteAccessConfig<T>, user: any): boolean {
		const userType: string | undefined = user.role;

		// skip route not visible
		if (!config.visibleOnMenu) return false;

		// skip no user type
		if (!userType) return true;

		// default/general routes always should be shown if route config has as router type
		if (config.userTypes.some((type: string) => type == userType)) return true;

		return false;
	}

	getPath(mainPath: TPageType): string {
		return '/' + mainPath;
	}

	getTitle<T extends TPageType>(
		page: T,
		child?: TChildrenPageType[T] extends string ? TChildrenPageType[T] : never,
	): string {
		const children = this.routeAccess[page].routeConfig.children;
		if (child && children && children[child]) {
			return children[child].title ?? '';
		}
		return this.routeAccess[page].title ?? '';
	}

	canActivate(mainPath: TPageType, url: any, user: any): boolean {
		const userType: string | undefined = user.role;
		const mainRoute: IRouteAccessConfig<any> = this.routeAccess[mainPath];

		// skip no user type
		if (!userType) return true;

		// Check if the user type is allowed on the main route
		const canAccessMainRoute: boolean = mainRoute.userTypes.some(type => type === userType);

		if (!canAccessMainRoute) return false;

		// Directly return true if it's an exact match
		if (url === this.getPath(mainPath)) return true;

		// If the main route does not have any children, the user can access it
		if (!mainRoute.routeConfig.children) return true;

		// Check each child route to see if it matches the given URL
		for (const key in mainRoute.routeConfig.children) {
			const childRoute = mainRoute.routeConfig.children[key];
			if (this.matchUrl(mainPath, childRoute.path, url)) {
				// If the child route does not specify user types, inherit access from the main route
				if (!childRoute.userTypes) {
					return true;
				}
				// Check if the user type is allowed on the child route
				return childRoute.userTypes.some(type => type === userType);
			}
		}

		// If no child routes match the URL, the user cannot access it
		return false;
	}

	private matchUrl(mainPath: TPageType, path: string, url: string) {
		const patternedPath: string = this.getPath(mainPath) + '/' + path;
		// Check if the path uses a generic parameter pattern (e.g., /:anything)
		if (path.includes(':')) {
			// Create a regex pattern to match parameterized paths, capturing segments after '/:'
			const regexPattern = patternedPath.replace(/\/:([^/]+)/g, '/([^/]+)') + '.*';

			const regex = new RegExp('^' + regexPattern + '$');

			return regex.test(url);
		} else {
			const baseUrl = url.split('?')[0];
			return patternedPath === baseUrl;
		}
	}
}
