import {
	booleanAttribute,
	Component,
	HostBinding,
	inject,
	Input,
	numberAttribute,
	OnInit,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '@shared/services/app.service';
import { TColor } from '@shared/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { AppStyles } from '@shared/services/app-styles.service';

const GOOGLE = [
	'check',
	'light_mode',
	'dark_mode',
	'autorenew',
	'search_off',
	'drag_handle',
	'filter_list',
	'error_outline',
] as const;

type TIconGoogle = (typeof GOOGLE)[number];

const KONE = [
	'user',
	'edit',
	'book',
	'alert-triangle-filled',
	'check-circle-filled',
	'chevron_down',
	'chevron_up',
	'chevron_left',
	'chevron_right',
	'project',
	'building',
	'group',
	'equipment',
	'solution',
	'close',
	'search',
	'share',
	'info',
	'setting',
	'user-group',
	'swap-vertical',
	'backtop',
	'dashboard',
	'layers',
	'scan',
	'elevator-door-opening',
	'elevator_directions',
	'settings',
	'lock-on',
	'link',
	'delete',
	'copy',
	'document',
	'back-previous',
	'history',
	'check-circle',
	'bars-one-filled',
	'spinner',
	'arrow-left',
	'arrow-right',
	'sun',
	'moon',
] as const;

// new icon -> must be also added to 'iconKONE' array in icon component
type TIconKONE = (typeof KONE)[number];

export type TIcon = TIconGoogle | TIconKONE;

type TIconType = 'svg' | 'mat-icon';

@Component({
	standalone: true,
	selector: 'icon',
	template: `
		<div
			class="align-center-center"
			[matTooltip]="tooltip | translate"
			[matTooltipPosition]="tooltipPosition"
			[matBadge]="badge"
			[matBadgeHidden]="badgeHidden"
			[matBadgePosition]="badgePosition"
			[class.disable]="disabled"
			[class.remove-margin]="removeMargin">
			@if (!loading) {
				<!-- KONE ICONS (SVG) -->
				@if (type == 'svg') {
					<mat-icon
						[style.--icon-size]="size + 'px'"
						[style.--color]="!disabled && customColor ? customColor : colorHex"
						[style.color]="!disabled && customColor ? customColor : colorHex"
						[style.transform]="rotate ? 'rotate(' + rotateDeg + 'deg)' : ''"
						[svgIcon]="_name || ''">
					</mat-icon>
				} @else if (type == 'mat-icon') {
					<!-- ANGULAR MATERIAL ICONS (GOOGLE) -->
					<mat-icon
						[style.--icon-size]="size + 'px'"
						[style.color]="!disabled && customColor ? customColor : colorHex"
						[style.transform]="rotate ? 'rotate(' + rotateDeg + 'deg)' : ''"
						[class.material-icons-outlined]="!filled">
						{{ _name }}
					</mat-icon>
				} @else {
					@if (type == 'png' && _name) {
						<img [ngSrc]="src" [width]="size" [height]="size" [alt]="_name" />
					} @else {
						Please provide icon name
					}
				}
			} @else {
				<div class="loading">
					<div class="item icon" [style]="'--loading-icon-size: ' + size + 'px'"></div>
				</div>
			}
		</div>
	`,
	styles: `
		mat-icon {
			transition: all var(--transition-duration-half);
			margin: 0 !important;
		}

		.remove-margin .mat-icon {
			margin: 0 !important;
		}

		.disable .mat-icon {
			pointer-events: none;
			opacity: 0.5;
			color: var(--disabled-color);
		}
	`,
	imports: [
		CommonModule,
		NgOptimizedImage,
		MatIconModule,
		MatTooltipModule,
		MatBadgeModule,
		TranslateModule,
	],
})
export class IconComponent implements OnInit {
	private appStyles: AppStyles = inject(AppStyles);

	@Input({ required: true })
	set name(name: TIcon) {
		this.set(name);
	}
	@Input({ transform: numberAttribute }) size: number;
	@Input() customColor: string; // overwrites color
	@Input() color: TColor;
	@Input({ transform: booleanAttribute }) rotate: boolean;
	@Input({ transform: numberAttribute }) rotateDeg: number;
	@Input() tooltip: string = '';
	@Input() tooltipPosition: 'above' | 'below' = 'below';
	@Input() badge: string = '';
	@Input() badgePosition: 'above' | 'below' = 'below';
	@Input({ transform: booleanAttribute }) badgeHidden: boolean;
	@Input({ transform: booleanAttribute }) disabled: boolean;
	@Input({ transform: booleanAttribute }) removeMargin: boolean;
	@Input({ transform: booleanAttribute }) filled: boolean;
	@Input() loading: boolean | undefined | null;

	private sanitizer: DomSanitizer = inject(DomSanitizer);
	private iconRegistry: MatIconRegistry = inject(MatIconRegistry);
	private appService: AppService = inject(AppService);

	dir: string = 'assets/icons/';
	src: string = '';
	colorHex: string = '';
	_name: string = '';
	type: TIconType;

	ngOnInit() {
		this.set(this._name);
	}

	set(name: string) {
		if (!name) return;

		this._name = name;

		if (!this.size) this.size = this.appStyles.getNumberFromPixels('iconSize');

		this.type = KONE.includes(name as any) ? 'svg' : 'mat-icon';
		this.colorHex = this.appStyles.getColor(this.color);

		this.src = this.dir + this.type + '/' + name + '.' + this.type;

		if (this.type == 'svg') {
			if (this.appService.svgIconsLoaded().indexOf(name) > -1) return;

			this.iconRegistry.addSvgIcon(
				name,
				this.sanitizer.bypassSecurityTrustResourceUrl(this.src),
			);

			// store loaded svg icon -> prevent adding svg
			this.appService.addSvgIconsLoaded([name]);
		}
	}

	@HostBinding('class')
	get class() {
		return 'icon';
	}
}
