import { Routes } from '@angular/router';
import { Injector } from '@angular/core';
import { TRouteAccess } from '@shared/interfaces/route.interface';
import { AppRouteService } from '@shared/services/app-route.service';

const injector = Injector.create({
	providers: [{ provide: AppRouteService, useClass: AppRouteService }],
});
const appRouteService = injector.get(AppRouteService);
const routeAccess: TRouteAccess = appRouteService.routeAccess;

export const routes: Routes = [
	{ path: '', redirectTo: 'error', pathMatch: 'full' },
	{
		path: 'error',
		loadComponent: () =>
			import('@pages/landing/landing.component').then(mod => mod.LandingComponent),
	},
	{
		path: 'info',
		loadComponent: () => import('@pages/info/info.component').then(mod => mod.InfoComponent),
	},
	{
		path: ':code',
		loadComponent: () =>
			import('@pages/landing/landing.component').then(mod => mod.LandingComponent),
	},
];
