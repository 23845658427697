import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { AppService } from '@shared/services';
import { inject } from '@angular/core';

export const HeadersInterceptor: HttpInterceptorFn = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
) => {
	const appService = inject(AppService);
	request = request.clone({
		headers: request.headers.set('x-session-id', appService.uuid()),
	});

	return next(request);
};
