import { computed, inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AppStyles } from '@shared/services/app-styles.service';

@Injectable({
	providedIn: 'root',
})
export class SnackBarService {
	private translate: TranslateService = inject(TranslateService);
	private toastr: ToastrService = inject(ToastrService);

	private appStyles = inject(AppStyles);

	timeout = computed(() => {
		return Number(this.appStyles.getNumberFromTime('transitionDurationHalf'));
	});

	private readonly DEFAULT_DELAY: number = 3000;

	success(tKey: string, title: string, delay: number = this.DEFAULT_DELAY): void {
		setTimeout(() => {
			this.toastr.success(this.translate.instant(tKey), title, {
				...this.config(),
				timeOut: delay,
				extendedTimeOut: delay,
			});
		}, this.timeout());
	}

	warn(tKey: string, title: string, delay: number = this.DEFAULT_DELAY): void {
		setTimeout(() => {
			this.toastr.error(this.translate.instant(tKey), title, {
				...this.config(),
				timeOut: delay,
			});
		}, this.timeout());
	}

	alert(tKey: string, title: string, delay: number = this.DEFAULT_DELAY): void {
		setTimeout(() => {
			this.toastr.warning(this.translate.instant(tKey), title, {
				...this.config(),
				timeOut: delay,
			});
		}, this.timeout());
	}

	info(tKey: string, title: string, delay: number = this.DEFAULT_DELAY): void {
		setTimeout(() => {
			this.toastr.info(this.translate.instant(tKey), title, {
				...this.config(),
				timeOut: delay,
			});
		}, this.timeout());
	}

	clearAll() {
		this.toastr.clear();
	}

	private config(): Partial<IndividualConfig> {
		return {
			timeOut: 4000,
			extendedTimeOut: 4000,
			tapToDismiss: false,
			closeButton: true,
			progressBar: true,
		};
	}
}
