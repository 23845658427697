import { effect, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TIcon } from '@shared/components/icon/icon.component';
import { AppStorageService } from '@shared/services/app-storage.service';
import { environment } from '@env/environment';
import { AppStyles } from '@shared/services/app-styles.service';

export type TTheme = 'dark-theme' | 'light-theme';

export interface ITheme {
	theme: TTheme;
	text: string;
}

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	private overlayContainer: OverlayContainer = inject(OverlayContainer);
	private appStorage: AppStorageService = inject(AppStorageService);
	private appStyles: AppStyles = inject(AppStyles);

	private storageName: string = environment.localStorageName + '_theme';

	private _theme: WritableSignal<TTheme> = signal('light-theme');
	private _iconTheme: WritableSignal<TIcon> = signal('sun');
	private _textTheme: WritableSignal<string> = signal('global.light_theme');

	theme: Signal<TTheme> = this._theme.asReadonly();
	iconTheme: Signal<TIcon> = this._iconTheme.asReadonly();
	textTheme: Signal<string> = this._textTheme.asReadonly();

	themes: Signal<TTheme[]> = signal(['dark-theme', 'light-theme']);
	themesList: Signal<ITheme[]> = signal<ITheme[]>([
		{ theme: 'light-theme', text: 'global.light_theme' },
		{ theme: 'dark-theme', text: 'global.dark_theme' },
	]);

	constructor() {
		effect(
			() => {
				// this.appStorage.setItem(this.storageName, this.theme());
				if (this.theme()) {
					setTimeout(() => this.appStyles.set(), 100);
				}
			},
			{
				allowSignalWrites: true,
			},
		);
		effect(
			() => {
				// this.appStorage.setItem(this.storageName, this.theme());
				if (this.appStyles.styles().appBackground) {
					this.applyThemeToMetaTag();
				}
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	fnInit() {
		// const theme = this.appStorage.getItem(this.storageName) || this.theme();
		this.detectSystemTheme();
		this.applyTheme(this._theme());
	}

	trigger(): void {
		if (this.theme() == 'dark-theme') this.applyTheme('light-theme');
		else this.applyTheme('dark-theme');
	}

	applyTheme(theme: TTheme): void {
		this.applyThemeOnOverlays(theme); // Apply the current theme on components with overlay (e.g. Dropdowns, Dialogs)
		this.applyThemeToBodyApp(theme); // Apply the current theme on body app
		this.applyThemeToHtmlApp(theme); // Apply the current theme on body app

		this._theme.set(theme);

		if (this.theme() === 'dark-theme') {
			this._iconTheme.set('moon');
			this._textTheme.set('global.dark_theme');
		} else {
			this._iconTheme.set('sun');
			this._textTheme.set('global.light_theme');
		}
	}

	applyThemeOnOverlays(theme: TTheme): void {
		const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
		if (this.themes().length) overlayContainerClasses.remove(...this.themes());

		overlayContainerClasses.add(theme);
	}

	applyThemeToBodyApp(theme: TTheme): void {
		const body: any = document.getElementById('body');
		const bodyClasses = body.classList;
		if (this.themes().length) bodyClasses.remove(...this.themes());

		body.classList.add(theme);
	}

	applyThemeToHtmlApp(theme: TTheme): void {
		const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
		const htmlClasses = html.classList;
		if (this.themes().length) htmlClasses.remove(...this.themes());

		html.classList.add(theme);
	}

	applyThemeToMetaTag(): void {
		const themeColorMetaTag: Element | null = document.querySelector(
			'meta[name="theme-color"]',
		);
		if (!themeColorMetaTag) return;
		themeColorMetaTag.setAttribute('content', this.appStyles.styles().appBackground);
	}

	private detectSystemTheme() {
		const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		this.applyTheme(darkModeMediaQuery.matches ? 'dark-theme' : 'light-theme');

		// Use addEventListener instead of addListener
		darkModeMediaQuery.addEventListener('change', e => {
			this.applyTheme(e.matches ? 'dark-theme' : 'light-theme');
		});
	}
}
