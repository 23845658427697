import { inject, Injectable } from '@angular/core';
import { ThemeService } from '@shared/services/theme.service';
import { AppLanguageService } from '@shared/services/app-language.service';
import { AppService } from '@shared/services/app.service';

@Injectable({
	providedIn: 'root',
})
export class AppStarterService {
	private themeService: ThemeService = inject(ThemeService);
	private appLanguageService: AppLanguageService = inject(AppLanguageService);
	private appService: AppService = inject(AppService);

	initServices(): void {
		this.appService.fnInit();
		this.themeService.fnInit();
		this.appLanguageService.fnInit();
	}
}
