import { booleanAttribute, ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TColor } from '@shared/interfaces';
import { AppStyles } from '@shared/services/app-styles.service';

@Component({
	standalone: true,
	selector: 'item-title',
	template: `
		@if (!loading) {
			<div class="flex-row align-start-center">
				<div [class.flex-row]="!suffixBellow" [class.flex-column]="suffixBellow">
					@if (label) {
						<span
							class="font-weight-semi-bold user-select-text"
							[class.font-size-super-lg]="textFontSuperLarge"
							[class.font-size-lg]="
								!textFontSuperLarge && !textFontMedium && !textFontNormal
							"
							[class.font-size-normal]="textFontNormal"
							[class.font-size-md]="textFontMedium"
							[style.color]="appStyles.getColor(color)"
							(click)="$event.stopPropagation()">
							{{ label | translate }}
						</span>
					}
					@if (suffix) {
						@if (!suffixBellow) {
							&nbsp;
						}
						<span
							class="text-color-soft user-select-text"
							(click)="$event.stopPropagation()">
							{{ suffix | translate }}
						</span>
					}
				</div>

				<ng-content></ng-content>
			</div>
		} @else {
			<div class="loading item-margin-right">
				<div class="item height-large medium border-radius"></div>
			</div>
		}
	`,
	imports: [TranslateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCardTitleComponent {
	appStyles: AppStyles = inject(AppStyles);

	@Input() label: string;
	@Input() suffix: string;
	@Input() color: TColor;
	@Input({ transform: booleanAttribute }) loading: boolean | null;
	@Input({ transform: booleanAttribute }) textFontSuperLarge: boolean;
	@Input({ transform: booleanAttribute }) textFontNormal: boolean;
	@Input({ transform: booleanAttribute }) textFontMedium: boolean;
	@Input({ transform: booleanAttribute }) suffixBellow: boolean;
}
