import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[app-background]',
})
export class AppBackgroundDirective {
	@HostBinding('class')
	get class() {
		return 'app-background';
	}
}

@Directive({
	standalone: true,
	selector: '[primary-layer-background-color]',
})
export class PrimaryLayerColorDirective {
	@HostBinding('class')
	get class() {
		return 'primary-layer-background-color';
	}
}

@Directive({
	standalone: true,
	selector: '[secondary-layer-background-color]',
})
export class SecondaryLayerColorDirective {
	@Input({ alias: 'secondary-layer-background-color', transform: booleanAttribute })
	state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'secondary-layer-background-color' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[tertiary-layer-background-color]',
})
export class TertiaryLayerColorDirective {
	@Input({ alias: 'tertiary-layer-background-color', transform: booleanAttribute })
	state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'tertiary-layer-background-color' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[fourth-layer-background-color]',
})
export class FourthLayerColorDirective {
	@HostBinding('class')
	get class() {
		return 'fourth-layer-background-color';
	}
}

@Directive({
	standalone: true,
	selector: '[fifth-layer-background-color]',
})
export class FifthLayerColorDirective {
	@HostBinding('class')
	get class() {
		return 'fifth-layer-background-color';
	}
}

@Directive({
	standalone: true,
	selector: '[transparent-color]',
})
export class TransparentColorDirective {
	@Input({ alias: 'transparent-color', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('style.background-color')
	get style() {
		return this.state ? 'transparent' : '';
	}
}
