import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[page-margin-inline]',
})
export class PageMarginInlineDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-inline';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-block]',
})
export class PageMarginBlockDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-block';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-top]',
})
export class PageMarginTopDirective {
	@Input({ alias: 'page-margin-top', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-top' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-half-top]',
})
export class PageMarginTopHalfDirective {
	@Input({ alias: 'page-margin-half-top', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-half-top' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-bottom]',
})
export class PageMarginBottomDirective {
	@Input({ alias: 'page-margin-bottom', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-bottom' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-half-bottom]',
})
export class PageMarginBottomHalfDirective {
	@Input({ alias: 'page-margin-half-bottom', transform: booleanAttribute }) state: boolean = true;

	@HostBinding('class')
	get class() {
		return this.state ? 'page-margin-half-bottom' : '';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-left]',
})
export class PageMarginLeftDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-left';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-half-left]',
})
export class PageMarginLeftHalfDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-half-left';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-right]',
})
export class PageMarginRightDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-right';
	}
}

@Directive({
	standalone: true,
	selector: '[page-margin-right-half]',
})
export class PageMarginRightHalfDirective {
	@HostBinding('class')
	get class() {
		return 'page-margin-right-half';
	}
}
